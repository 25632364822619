<template>
  <b-col cols="6" class="d-flex justify-content-end">
    <b-button
      size="sm"
      variant="primary"
      class="text-nowrap"
      @click="setIsCreateServiceSettingieModal({is: true, id: 0})"
    >
      مرحلة جديدة
      <unicon class="ml-1" name="plus" width="18" height="18" fill="#fff" />
    </b-button>
    <createCompanyImagesModal />
  </b-col>
</template>
<script>
import { mapActions } from 'vuex';
import createCompanyImagesModal from "./create-service-modal.vue";
  export default {
    components: {
      createCompanyImagesModal
    },
    methods: {
      ...mapActions(["setIsCreateServiceSettingieModal"])
    }
  };
</script>
