<template>
  <div>
    <ValidationObserver ref="observer">
      <b-form
        slot-scope="{ validate }"
        @submit.prevent="validate().then((suc) => {if(suc && (stageDto.imageDto.base64 || stageDto.imageDto.id) && stageDto.description) onSubmit()})"
      >
        <b-sidebar bg-variant="white" v-model="is" backdrop shadow>
          <template #header>
            <div
              class="d-flex justify-content-between align-items-center px-1 py-50 bg-light"
            >
              <h5 class="m-0">
                <strong> {{ stageDto.id ? 'تعديل المرحلة': 'إضافة مرحلة جديدة'}}</strong>
              </h5>
              <b-button
                @click="is = false"
                size="sm"
                variant="flat-secondary"
                class="btn-icon rounded-circle"
              >
                <unicon name="times" width="16" height="16" />
              </b-button>
            </div>
          </template>
          <b-col>
            <BTextInputWithValidation
              rules="required|positive"
              label="ترتيب المرحلة"
              name="ترتيب المرحلة"
              type="number"
              v-model="stageDto.order"
              placeholder="أدخل رقم الترتيب"
            />
            <label>صورة</label>
            <fileDragDrop
              @uploaded="uploadThumbImage"
              id="employee-photo"
              height="100px"
              type="image"
              title="صورة جديدة"
            />
            <img
              v-if="!!stageDto.imageDto.base64 || !!stageDto.imageDto.path"
              :src="!stageDto.imageDto.base64 ? stageDto.imageDto.path.replace('~', $store.getters['app/domainHost']) : stageDto.imageDto.base64"
              style="max-height: 320px; object-fit: fill;"
              class="w-100"
            />
            <small class="text-danger" v-show="!(stageDto.imageDto.base64 || stageDto.imageDto.id)">صورة المرحلة مطلوبة</small>
            <div class="editor-container mt-2">
              <quill-editor
                class="editor"
                ref="myTextEditor"
                :value="stageDto.description"
                :options="editorOption"
                @change="onEditorChange"
              />
            </div>
            <small class="text-danger mt-5" v-show="!stageDto.description">شرح المرحلة مطلوب</small>
          </b-col>
          
          <template #footer>
            <div class="d-flex p-1 border-top">
              <b-button
                v-if="stageDto.id"
                variant="outline-danger"
                type="button"
                @click="deleteStage(stageDto.id)"
              >حذف</b-button>
              <b-button
                variant="outline-primary"
                class="ml-auto mr-1"
                @click="is = false"
              >إلغاء</b-button>
              <b-button
                variant="primary"
                type="submit"
              >تم</b-button>
            </div>
          </template> 
        </b-sidebar>
      </b-form>
    </ValidationObserver>
  </div>
</template>
<script>
  import BTextInputWithValidation from "@core/components/inputs/BTextInputWithValidation";
  import fileDragDrop from "@core/components/file-drag-drop/file-drag-drop.vue";
  import { ValidationObserver } from "vee-validate";
  import { mapGetters, mapActions } from 'vuex';
  import { quillEditor } from "vue-quill-editor";
  import hljs from 'highlight.js'

  export default {
    components: {
      ValidationObserver,
      fileDragDrop,
      quillEditor,
      BTextInputWithValidation
    },
    props: {
      lang: String
    },
    data: () => ({
      editorOption: {
         modules: {
              toolbar: [
                  ['bold', 'italic', 'underline', 'strike'],
                  ['blockquote', 'code-block'],
                  [{ 'header': 1 }, { 'header': 2 }],
                  [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                  [{ 'script': 'sub' }, { 'script': 'super' }],
                  [{ 'indent': '-1' }, { 'indent': '+1' }],
                  [{ 'direction': 'rtl' }],
                  [{ 'size': ['small', false, 'large', 'huge'] }],
                  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                  [{ 'font': [] }],
                  [{ 'color': [] }, { 'background': [] }],
                  [{ 'align': [] }],
                  ['clean'],
                  ['link', 'image', 'video']
              ],
          syntax: {
              highlight: text => hljs.highlightAuto(text).value
          }
        },
        placeholder: "ادخل شرح عن المرحلة",
      }
    }),
    computed: {
      ...mapGetters(["stageDto"]),
      editor() {
        return this.$refs.myTextEditor.quill
      },
      is: {
        get() {
          return this.$store.getters["isCreateServiceSettingieModal"];
        },
        set(is) {
          this.$store.dispatch("setIsCreateServiceSettingieModal", {is, id: this.stageDto.id});
        },
      },
    },
    methods: {
      ...mapActions(["actionStage", "deleteStage"]),
      uploadThumbImage(file) {
          this.stageDto.imageDto.base64 = file[0].base64;
      },
      onEditorChange(value) {
        this.stageDto.description = value.html
      },
      onSubmit() {
        this.stageDto.order = +this.stageDto.order
        this.actionStage(this.stageDto)
      }
    }
  };
</script>

<style lang="scss" scoped>
.editor-container {
  height: 270px;
  .editor {
    height: 200px;
  }
}
</style>